<template>
    <div class="page-container">
        <div class="contact">
            <div v-if="$organization.org == 'sjtu'">
                联系方式： <i class="fa fa-phone"></i> 34206060 <i class="fa fa-map-signs"></i> ：上海市东川路800号
                <span class="ml-3">©2019</span>&nbsp;<a href="http://net.sjtu.edu.cn">上海交通大学网络信息中心</a>&nbsp;<a
                    href="mailto:service@sjtu.edu.cn">zoom@sjtu.edu.cn</a>
            </div>
            <div v-if="$organization.org == 'ecupl'">
                联系方式： <i class="fa fa-phone"></i> 021-57090068 <i class="fa fa-map-signs"></i>
                松江校区：松江大学园区龙源路555号 <i class="fa fa-map-signs"></i> 长宁校区：万航渡路1575号
                <span class="ml-3">©2019</span>&nbsp;上海华东政法大学信息化办公室
            </div>
            <br/>
            支持的浏览器有：IE版本9以上，Chrome,Safari,Firefox，国内浏览器请使用极速模式。
        </div>
    </div>
</template>

<script>
    export default {
        name: "page-footer"
    }
</script>

<style lang="scss">
    .page-container {
        .contact {
            color:#eee;
            position: relative;
            text-align: center;
            padding: 15px 0 20px 4px;
            line-height: 26px;
            font-size: 14px;

            a {
                color: #b8f7ff;
            }
        }
    }
</style>
