<template>
    <div class="w-100 h-100 login" v-show="ready"
         style="background-image:url('/images/login-bg.jpg');background-repeat: no-repeat;background-size:cover">
        <div class="w-100 h-100" v-if="$organization.sso"><i class="el-icon-loading"></i></div>
        <div v-else>
            <page-header></page-header>
        </div>
        <div class="page-body">
            <div class="body-wrap">
                <div class="login page-container">
                    <div class="login-wrap">
                        <div class="login-bg">
                            <div class="login-table">
                                <div class="text-center login-title">
                                     登 录 | SIGN IN
                                </div>
                                <div class="line"></div>
                                <form class="clearfix" action="/" @submit.prevent="submitForm" v-if="admin_login">

                                    <div class="login-group mt-3">
                                        <div class="login-icon"><i class="el-icon-user"></i> &nbsp;</div>
                                        <div class="login-input-wrap">
                                            <input type="text" v-model="login.id"
                                                   placeholder="用户/邮箱" class="login-input" required>
                                        </div>
                                    </div>

                                    <div class="login-group mt-2">
                                        <div class="login-icon"><i class="el-icon-lock"></i> &nbsp;</div>
                                        <div class="login-input-wrap">
                                            <input type="password" v-model="login.password"
                                                   placeholder="密码" class="login-input" required>
                                        </div>
                                    </div>

                                    <div class="text-right mb-3">
                                        <a href="javascript:void(0);" @click="resetPassword">
                                            <small>忘记或重置密码</small>
                                        </a>
                                    </div>
                                    <div>
                                        <button type="submit" class="login-btn btn btn-block" :disable="process.loading"
                                                style="font-size:20px;">登 录
                                        </button>
                                    </div>

                                    <div class="text-muted mt-3 d-flex justify-content-between align-items-center">
                                        <div class="">
                                            <el-button type="text" @click="admin_login=false" style="padding: 0"
                                                       v-if="oauth">
                                                <img :src="'images/'+$organization.oauth_picture" style="height: 48px;"
                                                     v-if="$organization.oauth_picture">
                                                <span v-else><i
                                                    class="el-icon-link"></i> {{ $organization.oauth_title }}</span>
                                            </el-button>
                                        </div>
                                        <div v-if="oauth"><a href="http://net.sjtu.edu.cn" target="_blank"
                                                             v-if="helper">
                                            <small> 需要帮助?</small>
                                        </a>
                                        </div>
                                    </div>
                                </form>
                                <div v-if="!admin_login" class="flex-in-middle" style="height: 240px;">
                                    <h5 class="text-center text-dark">&nbsp;</h5>
                                    <div class="w-100">
                                        <button type="button" class="login-btn btn btn-block" :disable="process.loading"
                                                @click="jumpToOAuth2" style="font-size:20px;">
                                            {{
                                                $organization.oauth_title +
                                                ($organization.oauth_title && $organization.oauth_title.includes('登录') ? '' : '登录')
                                            }}
                                        </button>
                                        <div style="font-size:13px;text-align: center;margin-top: 20px;"
                                             class="text-muted">点击按键,跳转到 <b>{{
                                                $organization.oauth_title +
                                                ($organization.oauth_title && $organization.oauth_title.includes('登录') ? '' : '登录')
                                            }}</b>
                                        </div>
                                    </div>
                                    <div class="w-100 text-center">
                                        <el-button type="text" @click="admin_login=true"><i class="el-icon-link"></i>
                                            用户口令登录
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-footer">
            <page-footer></page-footer>
        </div>

        <el-dialog append-to-body width="50%" title="错误" :visible.sync="show_login_error"
                   :close-on-click-modal="false">

            <el-row :gutter="20">
                <el-col :span="24">
                    <div v-html="error_message"></div>
                </el-col>
            </el-row>
            <div slot="footer" class="">
                <el-button type="primary" @click="jumpToSignIn">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import PageFooter from "../components/page-footer";
import PageHeader from "../components/page-header";
import SignIn from "./mixins/signin-mixin"

export default {
    components: {PageHeader, PageFooter},
    mixins: [SignIn],

}
</script>

<style lang="scss">
$text-color: #1976d2;
$link-text-color: #546e7a;
.login {
    .page-body {
        height: 493px;
        position: relative;

        .login {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 999;

            .login-wrap {
                width: 382px;
                height: 401px;
                background-color: RGBA(0, 0, 0, 0.2);
                position: relative;
                margin: auto;
                border-radius: 8px;
                padding: 5px;

                .login-bg {
                    background-color: RGBA(255, 255, 255, 0.9);
                    border-radius: 8px;
                    width: 100%;
                    height: 100%;

                    .login-table {
                        width: 270px;
                        margin: auto;

                        .login-title {
                            color: $text-color;
                            font-size: 26px;
                            padding: 20px;
                        }

                        .line {
                            height: 5px;
                            margin-bottom: 19px;
                            border-bottom: 1px solid $text-color;
                        }

                        .login-icon {
                            background: $text-color;
                            width: 55px;
                            height: 38px;
                            border-radius: 5px 0 0 5px;
                            float: left;

                            i {
                                color: white;
                                font-size: 26px;
                                width: 100%;
                                text-align: center;
                                line-height: 38px;
                            }
                        }

                        .login-input-wrap {
                            height: 38px;
                            border-radius: 5px;
                            background-color: white;
                            border: 1px solid $text-color;

                            .login-input {
                                color: #999;
                                text-indent: 0.2em;
                                border: none;
                                outline: none;
                                width: 211px;
                                height: 36px;
                                padding-left: 10px;
                                border-radius: 0 5px 5px 0;
                                font-size: 18px;
                            }
                        }

                        .login-group {
                            border-radius: 5px;
                            width: 100%;
                            height: 52px;
                            line-height: normal;
                            position: relative;
                        }

                        .login-btn {
                            background-color: $text-color;
                            color: white;
                        }

                        .login-btn:hover {
                            background-color: lighten($text-color, 10%);
                            color: white;
                        }

                        a {
                            color: $link-text-color;

                            &:hover {
                                color: lighten($link-text-color, 10%)
                            }
                        }
                    }

                }
            }
        }
    }

    .page-footer {
        min-height: 87px;
    }

    .flex-in-middle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
}

</style>
